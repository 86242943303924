import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const FirstTimeLoginTwoFactorAuthentication = () => {
  return (
    <Layout>
      <Seo
        title="How to First-Time Login and Two-Factor Authentication"
        description={`Set up Two-Factor Authentication (2FA) securely on Synkli for enhanced account protection. Follow our simple guide after your first login.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`first-time-login-two-factor-authentication`} />
    </Layout>
  )
}

export default FirstTimeLoginTwoFactorAuthentication
